
*{
  box-sizing: border-box;
}

body{
  margin:0
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-repeat: none;
  background-size: cover;
  min-height: 100vh;
  background-color: rgb(232,232,232);
}

button:hover{
  background: lightcyan;
}

.appHeader{
  height: 3vh;
  background-color: black;
}

.appFooter{
  background-color: black;
  height: 3vh;
}

/* HEADER PAGE */

.headerHeader{
  display: flex;
  height: 10vh;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgba(164, 248, 248, 0.265); */
  padding-left: 10px;
  padding-right: 10px;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: 25px ;

}

.headerHeader2{
  display: flex;
  height: 10vh;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: 25px ;

}

.newLogo{
  display:flex;
  font-size: 20px;
  color: white;
  text-align: left;
}

.newLogoText{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: .75vh;
  
}

.logoText1{
  font-size: 24px;
  /* letter-spacing: -.5px; */
}

.logoText2{
  font-size: 16px;
}

.headerIcon{
  display: flex;
  height: 8vh;
  margin-top: 1vh;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgba(164, 248, 248, 0.365); */
  padding-left: 2.5vw;
  font-family:Georgia, 'Times New Roman', Times, serif;
  /* font-size: 25px ; */
  cursor:pointer;
}



.headerMenu{
  padding-right: 2.5vw;
  display: flex;

}

.headerMenuIcon{
  display:none
}

.menuBox{
  height:7vh;
  width:7vh;
  border-style: solid;
  border-color:black;
  border-radius: 18%;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: none;

}

.menuBox:hover{
  border-color: black;
}

.menuLine{
  height: .75vh;
  width: 5vh;
  background-color: black;
  border-radius: 25%;
  background-color: none;

}

.headerMenuItem{
  margin-right: 20px;
  color: white;
  text-decoration:white;
  background-color: none;
}

.headerMenuItem:hover{
  color:blue;
  cursor:pointer
}

.mobileHeader{
  display: none
}


