@media only screen and (max-width: 650px){


    /* Home Page */
    .homeWelcome{
        color:white
    }

    .homeBorder{
      width: 0vw;
    }
    .homePage{
      width:100vw
    }

    .rickyPage{
      /* margin-top: -5vh; */
      height:94vh;
    }

    .ricky{
      max-height:60vh;
    }

    /* HEADER PAGE */
    .headerHeader{
        display: flex;
        max-width: 100vw;
        justify-content: space-between;
        align-items: center;
        background-color: lightcyan;
        padding-left: 10px;
        padding-right: 10px;
        font-family:Georgia, 'Times New Roman', Times, serif;
        font-size: 25px ;
        color: blue;
        text-decoration: blue;
      }
      
      .headerIcon{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: lightcyan;
        padding-left: 10px;
        padding-right: 10px;
        font-family:Georgia, 'Times New Roman', Times, serif;
        font-size: 25px ;
        cursor:pointer;
      }
      
      .showMenu{
        padding-right: 15px;
        display: none;
      }
      
      .headerMenuItem{
        display:none;
        padding-left: 20px;
        text-decoration:blue;
        background-color: lightcyan;
      }
      
      .headerMenuIcon{
        padding-left: 20px;
        text-decoration:blue;
      }

      .headerMenuIcon:hover{
        color:black;
      }
      
      .mobileHeader{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        /* padding-right: 25px; */
      }
      
      .headerIcon{
        /* width:300px; */
        max-height: 15vh;
        max-width: 65vw;
      }

      .mobileMenu{
        padding-right: 15px;
        padding-bottom: 5px;
        padding-top: 5px;
        gap:20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: lightcyan;
        padding-left: 10px;
        padding-right: 10px;
        font-family:Georgia, 'Times New Roman', Times, serif;
        font-size: 25px ;
        color: black;
        text-decoration: white;
        font-weight:500;
        width:fit-content;
      }



      .headerMenuIcon{
        display:flex;
        cursor:pointer;
        /* background-color: white; */
      }

      .menuBox{
        background-color: lightcyan;
        max-width: 20vw;
      }

      .menuBox:hover{
        background-color: white;
      }

      .mobileMenuItem{
        text-decoration:black;
        cursor:pointer;
      }

      .mobileMenuItem:hover{
          color:white
      }


    /* LOGIN PAGE */

    .reg{
      margin-top: 14vh;
    }

    .log{
      margin-top: 20vh;
    }

    /* GLOSSARY PAGE*/
    
    .glossaryPage{
      width: 100vw;
    }

    .unitBox{
          width:80vw;
          height: fit-content;
      }

    /* UNIT PAGE */

    .unitPage{
      width:100vw;
    }

    .addName{
      width:50%;
    }

    .addDefinition{
      width:50%;
    }

    .newName{
      width: 50%;
    }
    
    .newDefinition{
      width:50%;
    }

    .itemBox{
      /* display:none; */
        width:80vw;
    }

    .itemDivider{
      /* display:none; */
      width:90%;
    }

    .itemName{
      /* display:none; */
      width:90%
    }

    .unitDefinitionHead{
      /* display:none; */
      width:90%
    }
    
    .unitDefinition{
      /* display:none; */
      max-width:95%;
      /* width:90%; */
      /* margin-left: -10; */
    }

    .unitDefinitionHead{
      /* display:none; */
      width:90%
    }

    .addToListButton{
      /* display:none; */
      max-width: 90%;
    }

    .toggleEditItem{
      /* display:none; */
      max-width: 90%;
    }

    .editItemBox{
      /* display:none; */
      max-width: 90%;
    }

  /* TOPIC PAGE */

  .topicSection{
    min-height: 200px;
    max-height:1000px;
    width: 95%;
    /* background-color: red; */
    margin-left: 0px;
    gap:3vw;
    justify-content: flex-start;
    /* margin-left: -50px; */
  }

  .topicList{
    display:flex;
    flex-direction: column;
    align-content: left ;
    width: 50%;
    /* background-color: green; */
    gap: 3vw;
  }

  /* LOGIN PAGE */
  .loginPage{
    width:100vw;
  }


  /* PROFILE PAGE */
  .profilePage{
    width:100vw
  }

  .profilePagePic{
    padding-right: 2vw;
  }

  .profileDivider{
    width:94vw;
  }

  .profilePic{
    width: 25vw;
    height: 25vw;
  }

  .changeProfileButtons{
    width:25vw;
    margin-left: .5vw;
    margin-bottom: -1vw;
  }

  .profileDetails{
    width: 25vw;
    height: 22.5vw;
  }

  .test{
    width:90vw
  }

  .termTop{
    margin-top: -1vh;
  }
  
  .myTerms{
    width:62vw;
    min-height: fit-content;
    /* background-color: green; */
  }

  .dropzone{
    /* padding-bottom: -5vh; */
    width:25vw;
    /* background-color: pink; */
    min-height: 8vh;
    align-self: center;
    padding:1vh;
  }

  .dropText{
    align-self: center;
    min-height: 8vh;
    margin:0;
    padding:0;

    /* background-color: green;
    margin-top: 1.5vh; */
  }

  .printButton{
    margin-top: -1vh;
  }


}