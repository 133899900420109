.glossaryBackground{
  background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0,0,0, 0.35)), url('./assets/newDesk2.jpg');
  background-repeat: none;
  background-size: cover;
  min-height: 100vh;
  display:flex;
  flex-direction: column;
}

.glossaryPage{
    /* background-image: url('https://devmountain.com/wp-content/uploads/2020/08/get-course.jpg'); */
    background-repeat: none;
    background-size: cover;
    min-height:80vh;
    width:80vw;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  
  .glossaryPageName{
    color:white;
  
  }

  
  .itemBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    height: fit-content;
    width: 70%;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 25px;
    /* padding: 10px; */
    border-style: solid;
    border-color: white;
  }
  
  .itemDivider{
    width:95%;
    height:.4vh;
    background-color: white;
    align-self: center;
    margin-bottom: 2.5vh;
    margin-left: 10px;
  }
  
  .toggleEditItem{
    padding-left: 10px;
    padding-bottom: 15px;
  }
  
  .addSection{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: left;
    width:45vw;
    padding-bottom: 10px;
  }
  
  .addItemBox{
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .addAddButton{
    width: 100px;
    align-self: center;
    margin-bottom: 10px;
  }
  
  .addSubmitButton{
    width:100px;
    align-self: center;
  }
  
  .addName{
    width:44.2vw;
  }
  
  .addDefinition{
    width:44.2vw;
    height:200px;
    margin-bottom: 10px;
  }
  
  .itemName{
    display:flex;
    width:95%;
    font-size: 30px;
    font-weight: bolder;
    color: white;
    margin-left: 10px;
    margin-top: 15px;
    text-decoration: none;
  }
  
  .addToListButton{
    margin-left: 10px;
  }
  
  .editItemBox{
    display:flex;
    flex-direction: column;
  }
  
  .newName{
    width:375px;
  }
  
  .newDefinition{
    width:375px;
    height:200px;
  }